$(document).ready(function () {
	//Hide element when smaller than 1025
	AOS.init({
		// Global settings:
		disable: function () {
			var maxWidth = 1024.1;
			return window.innerWidth < maxWidth;
		},
		offset: 120, // offset (in px) from the original trigger point
		delay: 0, // values from 0 to 3000, with step 50ms
		easing: "ease", // default easing for AOS animations
		once: false, // whether animation should happen only once - while scrolling down
		mirror: true, // whether elements should animate out while scrolling past them
		anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
	});
	if ($(window).width() < 1025) {
		$(".header-bottom").fadeIn(function () {
			$(".header-bottom").css({
				display: "flex",
			});
		});
		$(window).bind("resize", function (e) {
			mappingButton();
		});
	}
	mapHightlight();
	tabActive();
	swiperInit();
	toggleMobileMenu();
	stickyNav();
	//Declare NEW function Javascript
	backToTop();
	animationInit();
	appendHtml();
	searchToggle();
	tocFunction();
});

window.onscroll = function () {
	fixHeader();
};

function tocFunction() {
	$("#toc").toc({ content: "div.news-detail-fullcontent", headings: "h2,h3,h4,h5" });
}

function mapHightlight() {
	$(".image-map-wrapper img[usemap]").maphilight();
	$(".image-map-wrapper img[usemap]").rwdImageMaps();
	//map hight light text
	$(".tooltip").tooltipster({
		animation: "fade",
		delay: 200,
		trigger: "click",
		contentCloning: true,
		interactive: true,
		maxWidth: 320,
		minWidth: 280,
	});
}

function searchToggle() {
	$(".search-toggle").on("click", function () {
		$(".searchbox").toggleClass("open");
		if ($(".header-top-banner").hasClass("hidden")) {
			$(".searchbox.open").css({
				top: "55px",
			});
		}
		event.preventDefault();
		$('.searchbox  > input[type="text"]').focus();
	});

	$(".searchbox, .searchbox .close").on("click keyup", function (event) {
		if (
			event.target == this ||
			event.target.className == "close" ||
			event.keyCode == 27
		) {
			$(this).removeClass("open");
		}
	});
}


function animationInit() {
	var txt = $(".btn-split").text();
	$(".btn-split").attr("name", txt);
}

function appendHtml() {
	//Append Product Detail Form
	if ($("body").hasClass("product-detail-page")) {
		if ($(".subscrible-form").length) {
			$(".subscrible-form").insertBefore(".other-product");
		}
	}
}

function fixHeader() {
	if ($("body").hasClass("homepage")) {
		if ($(window).width() > 1025) {
			if (
				document.body.scrollTop > 100 ||
				document.documentElement.scrollTop > 100
			) {
				$("header").addClass("minimize");
			} else {
				$("header").removeClass("minimize");
			}
		}
	}
}

function backToTop() {
	let btn = $(".back-to-top");
	btn.on("click", function (e) {
		e.preventDefault();
		$("html, body").animate(
			{
				scrollTop: 0,
			},
			"300"
		);
	});
}

window.addEventListener("load", numService);
const numService = () => {
	let largeSliders = document.querySelectorAll(".service-swiper");
	largeSliders.forEach((slider, index) => {
		let sliderLength = slider.children[0].children.length;
		let result = sliderLength > 1 ? true : false;
		const swiper = new Swiper(slider, {
			direction: "horizontal",
			loop: result,
			speed: 1000,
		});
	});
};

function swiperInit() {
	var homerSwiper = new Swiper(".primary-banner .swiper-container", {
		// Optional parameters
		slidesPerView: 1,
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
	var topBanner = new Swiper(".top-banner .swiper-container", {
		// Optional parameters
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
	});

	var homerSwiper = new Swiper(".swiper-home-news  .swiper-container", {
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 1,
				slidesPerColumn: 1,
				spaceBetween: 10,
			},
			450: {
				slidesPerView: 2,
				slidesPerColumn: 2,
				spaceBetween: 30,
			},
			768: {
				slidesPerView: 3,
				slidesPerColumn: 2,
				spaceBetween: 30,
			},
			1025: {
				slidesPerView: 3,
				slidesPerColumn: 2,
				spaceBetween: 60,
			},
		},
	});
	var homerSwiper = new Swiper(".swiper-home-news-2  .swiper-container", {
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		spaceBetween: 30,
		lazy: {
			loadPrevNext: true,
		},
		breakpointsInverse: true,
		breakpoints: {
			1: {
				slidesPerView: 1,
			},
			450: {
				slidesPerView: 2,
			},
			768: {
				slidesPerView: 3,
			},
			1025: {
				slidesPerView: 4,
			},
		},
	
	});

	var swiper2Sync = new Swiper(".mySwiper", {
		spaceBetween: 10,
		slidesPerView: 1,
		freeMode: true,
		watchSlidesProgress: true,
		lazy: {
			loadPrevNext: true,
		},
	});
	var swiper2Main = new Swiper(".mySwiper2", {
		spaceBetween: 10,
		pagination: {
			el: ".swiper-2-sync .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		thumbs: {
			swiper: swiper2Sync,
		},
	});

	// 3 swiper sync
	var swiperSyncText = new Swiper(".swiper-sync-text", {
		// Optional parameters
		speed: 300,
		spaceBetween: 30,
		autoplay: {
			delay: 5000,
		},
		pagination: {
			el: ".swiper-sync .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
	});
	var swiperSyncBtn = new Swiper(".swiper-sync-btn", {
		// Optional parameters
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-sync .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		slidesPerView: 1,
	});
	var swiperSyncImg = new Swiper(".swiper-sync-img", {
		// Optional parameters
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-sync .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		slidesPerView: 1,
	});
	swiperSyncText.on("slideChange", function () {
		swiperSyncBtn.slideTo(swiperSyncText.activeIndex);
		swiperSyncImg.slideTo(swiperSyncText.activeIndex);
	});
	swiperSyncBtn.on("slideChange", function () {
		swiperSyncText.slideTo(swiperSyncBtn.activeIndex);
		swiperSyncImg.slideTo(swiperSyncBtn.activeIndex);
	});
	swiperSyncImg.on("slideChange", function () {
		swiperSyncBtn.slideTo(swiperSyncImg.activeIndex);
		swiperSyncText.slideTo(swiperSyncImg.activeIndex);
	});

	// 3 swiper sync ver 2

	var swiperSyncText2 = new Swiper(".swiper-sync-text-2", {
		// Optional parameters
		speed: 300,
		spaceBetween: 30,
		autoplay: {
			delay: 5000,
		},
		pagination: {
			el: ".swiper-sync-2 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
	});
	var swiperSyncBtn2 = new Swiper(".swiper-sync-btn-2", {
		// Optional parameters
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-sync-2 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		slidesPerView: 1,
	});
	var swiperSyncImg2 = new Swiper(".swiper-sync-img-2", {
		// Optional parameters
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-sync-2 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		slidesPerView: 1,
	});
	swiperSyncText2.on("slideChange", function () {
		swiperSyncBtn2.slideTo(swiperSyncText2.activeIndex);
		swiperSyncImg2.slideTo(swiperSyncText2.activeIndex);
	});
	swiperSyncBtn2.on("slideChange", function () {
		swiperSyncText2.slideTo(swiperSyncBtn2.activeIndex);
		swiperSyncImg2.slideTo(swiperSyncBtn2.activeIndex);
	});
	swiperSyncImg2.on("slideChange", function () {
		swiperSyncBtn2.slideTo(swiperSyncImg2.activeIndex);
		swiperSyncText2.slideTo(swiperSyncImg2.activeIndex);
	});

	//end 3 swiper sync
	var swiperSyncText3 = new Swiper(".swiper-sync-text-3", {
		// Optional parameters
		speed: 300,
		spaceBetween: 30,
		autoplay: {
			delay: 5000,
		},
		pagination: {
			el: ".swiper-sync-3 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
	});
	var swiperSyncBtn3 = new Swiper(".swiper-sync-btn-3", {
		// Optional parameters
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-sync-3 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		slidesPerView: 1,
	});
	var swiperSyncImg3 = new Swiper(".swiper-sync-img-3", {
		// Optional parameters
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},
		pagination: {
			el: ".swiper-sync-3 .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
		slidesPerView: 1,
	});
	swiperSyncText3.on("slideChange", function () {
		swiperSyncBtn3.slideTo(swiperSyncText3.activeIndex);
		swiperSyncImg3.slideTo(swiperSyncText3.activeIndex);
	});
	swiperSyncBtn3.on("slideChange", function () {
		swiperSyncText3.slideTo(swiperSyncBtn3.activeIndex);
		swiperSyncImg3.slideTo(swiperSyncBtn3.activeIndex);
	});
	swiperSyncImg3.on("slideChange", function () {
		swiperSyncBtn3.slideTo(swiperSyncImg3.activeIndex);
		swiperSyncText3.slideTo(swiperSyncImg3.activeIndex);
	});

	//Sync 3

	var swiperProductGallery = new Swiper(
		".swiper-product-gallery  .swiper-container",
		{
			// Optional parameters
			speed: 300,
			lazy: {
				loadPrevNext: true,
			},
			autoplay: {
				delay: 5000,
			},
			centeredSlides: true,

			observer: true,
			spaceBetween: 20,
			observeParents: true,
			breakpointsInverse: true,
			breakpoints: {
				200: {
					slidesPerView: 2,
				},

				768: {
					slidesPerView: 3,
				},
				1280: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".swiper-product-gallery .nav-arrow-next",
				prevEl: ".swiper-product-gallery .nav-arrow-prev",
			},
		}
	);

	$(".trinity-swiper .swiper-container").each(function (index, element) {
		var $this = $(this);
		$this.addClass("tri-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);
		$this
			.parents("section")
			.find(".swiper-pagination")
			.addClass("pagination-instance-tri-" + index);
		var swiper = new Swiper(".tri-instance-" + index, {
			speed: 300,
			autoplay: {
				delay: 5000,
			},
			lazy: {
				loadPrevNext: true,
			},

			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpointsInverse: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
			pagination: {
				el: ".pagination-instance-tri-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	$(".home-about .swiper-container").each(function (index, element) {
		var $this = $(this);
		$this.addClass("about-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".about-instance-" + index, {
			speed: 300,
			autoplay: {
				delay: 3000,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			pagination: {
				el: ".home-about .swiper-pagination",
				type: "bullets",
				clickable: true,
			},
		});
	});

	$(".single-swiper .swiper-container").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);
		$this
			.parents("section")
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 900,
			autoplay: {
				delay: 5000,
			},
			lazy: {
				loadPrevNext: true,
			},

			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});

	$(".four-swiper .swiper-container").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);

		var swiper = new Swiper(".instance-" + index, {
			speed: 300,
			autoplay: {
				delay: 5000,
			},
			lazy: {
				loadPrevNext: true,
			},

			observer: true,
			spaceBetween: 10,
			observeParents: true,
			breakpointsInverse: true,
			breakpoints: {
				200: {
					slidesPerView: 2,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 4,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
		});
	});
	$(".double-swiper .swiper-container").each(function (index, element) {
		var $this = $(this);
		$this.addClass("instance-double-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-" + index);
		$this
			.parents("section")
			.find(".swiper-pagination")
			.addClass("pagination-instance-dboule-" + index);
		var swiper = new Swiper(".instance-double-" + index, {
			speed: 300,
			autoplay: {
				delay: 5000,
			},
			lazy: {
				loadPrevNext: true,
			},

			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpointsInverse: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 2,
				},
			},
			navigation: {
				nextEl: ".btn-next-" + index,
				prevEl: ".btn-prev-" + index,
			},
			pagination: {
				el: ".pagination-instance-dboule-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
	var partnerSwiper = new Swiper(".about-partner .swiper-container", {
		speed: 300,
		autoplay: {
			delay: 5000,
		},
		lazy: {
			loadPrevNext: true,
		},

		observer: true,
		spaceBetween: 30,
		observeParents: true,
		breakpointsInverse: true,
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 2,
			},
			769: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 5,
			},
		},
		navigation: {
			nextEl: ".nav-arrow-next",
			prevEl: ".nav-arrow-prev",
		},
	});
}

//Toggle mobile menu

function toggleMobileMenu() {
	var $hamburger = $(".hamburger");
	$(".main-menu-toggle").on("click", function () {
		$(".navbar-primary-menu ").toggleClass("active-menu");
		$(this).toggleClass("is-active");
	});
	//mobile filter
	$(".mobile-toggle-filter").on("click", function () {
		$(".filter-navigation").slideToggle();
	});
	$(".remove-filter").on("click", function () {
		$(".filter-navigation").slideUp();
	});
}



//Tab active

function tabActive() {
	$(".tab-navigation li a").on("click", function () {
		$(this).parents(".tab-navigation").find("li").removeClass("active");
		$(this).parents("li").addClass("active");

		var display = $(this).attr("data-type");
		$(".tab-item").removeClass("active");
		$("#" + display).addClass("active");
	});
}

function mappingButton() {
	return new MappingListener({
		selector: ".fixed-side-nav ",
		mobileWrapper: ".social-wrapper",
		mobileMethod: "insertAfter",
		desktopWrapper: "footer",
		desktopMethod: "insertAfter",
		breakpoint: 1025,
	}).watch();
}
function mappingMenu() {
	return new MappingListener({
		selector: ".header-bottom ",
		mobileWrapper: ".mobile-wrapper",
		mobileMethod: "appendTo",
		desktopWrapper: ".header-top",
		desktopMethod: "insertAfter",
		breakpoint: 1025,
	}).watch();
}
